// require './swiper.js';

const toggle = document.querySelector('.navigation-toggle')
const mobilenav = document.querySelector('.navigation-container')
const groupposition = [].slice.call(document.querySelectorAll('.group__position'))
const person = [].slice.call(document.querySelectorAll('.person'))
const swiper = document.querySelector('.swiper-container')
const background = document.querySelector('.background')
const overlay = document.querySelector('.overlay')
const lawyerbackground = document.querySelector('.background--lawyers')
const bios = [].slice.call(document.querySelectorAll('.bio__person'))
const mobile = window.innerWidth < 500 ? true : false;



toggle.addEventListener('click', function(e){
  e.preventDefault()
  toggle.classList.toggle('open')
  mobilenav.classList.toggle('open')
  overlay.classList.toggle('open')
})

// groupposition.addEventListener('click', function(e){
//   e.preventDefault()
//   alert("toggle the directory for mobile")
// })

groupposition.forEach(element => {
  element.addEventListener('click', e => {
    e.preventDefault()
    e.target.parentNode.classList.toggle('is-open')
  })
})

// swiper.addEventListener("mouseover", function( event ) {   
//   // highlight the mouseout target
//   swiper.classList.add('is-active')

// });

// swiper.addEventListener("mouseout", function( event ) {   
//   // highlight the mouseout target
//   swiper.classList.remove('is-active')

// });

const deactivatePerson = () => {
  person.forEach(e => {
    e.classList.remove('is-active')
  })
}

const hideBios = (index) => {
  let bio = document.querySelector('.bio__person[data-index="' + index + '"]');
  bios.forEach(e => {
    e.style.display = 'none';
  })
  bio.style.display = 'block';
}

person.forEach(element => {
  element.addEventListener('click', e => {

    if (!mobile) {
      e.preventDefault()
      let currentPerson = e.target.parentNode.parentNode
      let currentIndex = currentPerson.getAttribute('data-index')
      let currentBackground = currentPerson.getAttribute('data-background')
      deactivatePerson()
      swiper.classList.add('is-active')
      currentPerson.classList.add('is-active')
      hideBios(currentIndex);
      background.style.backgroundImage = `url("${currentBackground}")`;
    }
    
    // lawyerbackground.style.backgroundImage = `url("${currentBackground}")`;
    // lawyerbackground.classList.add('visible');
  })
})

const mySwiper = new Swiper ('.swiper-container', {
  direction: 'horizontal',
  speed: 400,
  slidesPerView: 5,
  spaceBetween: 5,
  nextButton: '.swiper-button-next',
  prevButton: '.swiper-button-prev',
  keyboardControl: true,
  preloadImages: true,
  breakpoints: {
    1010: {
        slidesPerView: 4,
        spaceBetween: 40
    },
    900: {
        slidesPerView: 3,
        spaceBetween: 40
    },
    500: {
        slidesPerView: 1,
        spaceBetween: 0
    }
  },
  onSlideChangeEnd: function(swiper) {
    if (mobile) {
      let slideIndex = swiper.activeIndex + 1
      hideBios(slideIndex)
    }
  }
})

// document.addEventListener("DOMContentLoaded", (event) => {
//   swiper.classList.add('loaded');
// });

// document.querySelector('.contact__form').classList.add('sent');

// window.addEventListener("load", (event) => {
//   swiper.style.visibility = 'visible';
// });